<template>
  <div class="create-account-by-socials">
    <b-alert v-model="showLoginError" variant="danger" dismissible>{{ loginErrorText }}</b-alert>
    <LoginList @socialLogin="socialLogin" :items="links" class="create-account-by-socials__links" />
  </div>
</template>

<script>
import { getAuth, signInWithPopup, OAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import LoginList from '@/components/Login/LoginBySocials/LoginList'

export default {
  components: {
    LoginList
  },
  emits: ['changeToEmailForm'],
  data () {
    return {
      loginErrorText: '',
      showLoginError: false,
      links: [
        {
          id: 1,
          text: 'Continue with Facebook',
          imgName: 'Facebook.svg',
          provider: 'facebook'
        },
        {
          id: 2,
          text: 'Continue with Google',
          imgName: 'Google.svg',
          provider: 'google'
        }
      ]
    }
  },
  methods: {
    async socialLogin (providerName) {
      this.showLoginError = false
      this.loginErrorText = ''
      const auth = getAuth()
      let provider

      switch (providerName) {
        case 'facebook':
          provider = new FacebookAuthProvider()
          break
        case 'google':
          provider = new GoogleAuthProvider()
          break
        case 'apple':
          provider = new OAuthProvider('apple.com')
          break
      }

      try {
        const result = await signInWithPopup(auth, provider)
        if (result && result.user) {
          await this.$router.push({ name: 'Home' })
        }
      } catch (e) {
        this.showLoginError = true
        this.loginErrorText = 'Something went wrong!'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .create-account-by-socials {
    margin-bottom: 40px;
    &__links {
      margin-top: 32px;
      margin-bottom: 27px;
    }

    &__btn {
      width: 400px;
    }
  }

  @media (max-width: 590px) {
    .create-account-by-socials {
      &__btn {
        width: 100%;
      }
    }
  }

</style>
