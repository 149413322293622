<template>
  <b-row class="mb-4">
    <b-col>
      <b-button
        @click="$router.push({ name: 'Mapping' })"
        :variant="$route.name === 'Mapping' ? 'info' : 'outline-info'"
        class="mr-2 mb-2"
      >
        Services-Providers
      </b-button>
      <b-button
        @click="$router.push({ name: 'CountriesProviders' })"
        :variant="$route.name === 'CountriesProviders' ? 'info' : 'outline-info'"
        class="mr-2 mb-2"
      >
        Countries-Providers
      </b-button>
      <b-button
        @click="$router.push({ name: 'MappingServices' })"
        :variant="$route.name === 'MappingServices' ? 'info' : 'outline-info'"
        class="mb-2"
      >
        Services
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {}
</script>
