import { useAuth } from '@/use/auth'
import { useStore } from 'vuex'

export function useErrorHandler() {
  const { logout } = useAuth()
  const store = useStore()

  const handleNotAuthorizedError = async () => {
    logout()
    store.commit('alert/add', {
      id: Date.now(),
      text: 'You do not have access to admin search',
      timeout: 4000,
      name: 'not_authorized_error'
    })
  }

  const handleInvalidRequestParams = async (e) => {
    let message = 'Invalid request params'
    if (e && e.body && e.body.errorMessage) {
      message = e.body.errorMessage
    }
    store.commit('alert/add', {
      id: Date.now(),
      text: message,
      timeout: 3000,
      name: 'invalid_request_params'
    })
  }

  const handleNotFoundError = async () => {
    store.commit('alert/add', {
      id: Date.now(),
      text: 'The resource is NOT found',
      timeout: 3000,
      name: 'not_found_error'
    })
  }

  const showCommonError = (e) => {
    const message =
      e.status === 500 && e.body?.errorMessage && e.body.errorMessage !== 'Internal Error' ? e.body.errorMessage : ''
    store.commit('alert/add', {
      id: Date.now(),
      text: `Something went wrong! ${message}`,
      type: 'error',
      timeout: message ? 7000 : 3000,
      name: 'common_error'
    })
  }

  const handleCommonErrors = async (e) => {
    switch (e.status) {
      case 400:
        await handleInvalidRequestParams(e)
        break
      case 401:
        await handleNotAuthorizedError()
        break
      case 404:
        await handleNotFoundError()
        break
      default:
        showCommonError(e)
    }
  }

  return { handleCommonErrors }
}
