<template>
  <div class="control email-control">
    <input
      :placeholder="placeholder"
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      ref="emailInput"
    >

    <div v-if="errorMessage" class="control__error">
      {{ correctError(errorMessage) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    errorMessage: String,
    placeholder: String
  },

  emits: ['update:modelValue'],

  methods: {
    correctError (errorMessage) {
      switch (errorMessage) {
        case 'required':
          return 'Enter email address'
        case 'email':
          return 'This email address isn’t correct'
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .email-control {
    &:before {
      position: absolute;
      content: '';
      left: 18px;
      top: 24px;
      width: 13px;
      height: 11px;
      background: url('../../../assets/form/email.svg');
      background-size: cover;
      z-index: 2;
    }
  }
</style>
