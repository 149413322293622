<template>
  <b-container fluid class="mt-4 max-width-1600">
    <HeaderBl />
    <router-view />
  </b-container>
</template>

<script>
import HeaderBl from '@/components/HeaderBl'

export default {
  components: { HeaderBl }
}
</script>

<style lang="scss" scoped>
.max-width-1600 {
  max-width: 1600px;
}
</style>
