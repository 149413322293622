<template>
  <div class="control password-control">
    <div>
      <input
        class="create-account-by-email__control create-account-by-email__password"
        :placeholder="placeholder"
        :type="inputType"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        aria-label="password"
      >
      <div @click="togglePasswordVisibility()" class="togglePasswordVisibility">
        <template v-if="visible">
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.80465 0.861969L1.86194 1.80468L3.38798 3.33072C1.61008 4.76026 0.77385 6.61078 0.720011 6.73697L0.608032 6.99999L0.720011 7.26301C0.808678 7.47034 2.95196 12.3333 7.99996 12.3333C9.37752 12.3333 10.5314 11.9663 11.4921 11.4349L13.1953 13.138L14.138 12.1953L2.80465 0.861969ZM7.99996 1.66666C7.19263 1.66666 6.46665 1.80041 5.80465 2.01041L6.9023 3.10806C7.24964 3.04206 7.61263 2.99999 7.99996 2.99999C11.5973 2.99999 13.445 6.05872 13.927 6.99739C13.731 7.37272 13.3015 8.10129 12.6289 8.83462L13.5794 9.78515C14.6994 8.57315 15.2366 7.36368 15.2799 7.26301L15.3919 6.99999L15.2799 6.73697C15.1912 6.52964 13.048 1.66666 7.99996 1.66666ZM4.3411 4.28384L5.70829 5.65103C5.47192 6.04603 5.33329 6.50592 5.33329 6.99999C5.33329 8.47066 6.52929 9.66666 7.99996 9.66666C8.49403 9.66666 8.95392 9.52802 9.34892 9.29166L10.4987 10.4414C9.78286 10.7808 8.95216 11 7.99996 11C4.40263 11 2.55488 7.94126 2.07288 7.00259C2.34996 6.47195 3.09345 5.24187 4.3411 4.28384ZM8.14189 4.34765L10.6523 6.85806C10.5796 5.50273 9.49722 4.42031 8.14189 4.34765ZM6.7161 6.65884L8.3411 8.28384C8.23193 8.31319 8.11857 8.33332 7.99996 8.33332C7.26463 8.33332 6.66663 7.73532 6.66663 6.99999C6.66663 6.88138 6.68676 6.76802 6.7161 6.65884Z" fill="#848194"/>
          </svg>
        </template>
        <template v-else>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99996 2.66667C2.66663 2.66667 0.666626 8 0.666626 8C0.666626 8 2.66663 13.3333 7.99996 13.3333C13.3333 13.3333 15.3333 8 15.3333 8C15.3333 8 13.3333 2.66667 7.99996 2.66667ZM7.99996 4.00001C11.5173 4.00001 13.2964 6.84473 13.8724 7.9974C13.2957 9.14207 11.5033 12 7.99996 12C4.48263 12 2.70356 9.15528 2.12756 8.00261C2.7049 6.85794 4.49663 4.00001 7.99996 4.00001ZM7.99996 5.33334C6.52729 5.33334 5.33329 6.52734 5.33329 8C5.33329 9.47267 6.52729 10.6667 7.99996 10.6667C9.47263 10.6667 10.6666 9.47267 10.6666 8C10.6666 6.52734 9.47263 5.33334 7.99996 5.33334ZM7.99996 6.66667C8.73663 6.66667 9.33329 7.26334 9.33329 8C9.33329 8.73667 8.73663 9.33334 7.99996 9.33334C7.26329 9.33334 6.66663 8.73667 6.66663 8C6.66663 7.26334 7.26329 6.66667 7.99996 6.66667Z" fill="#848194"/>
          </svg>
        </template>
      </div>
    </div>

    <div v-if="errorMessage" class="control__error">
      {{ correctError(errorMessage) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    errorMessage: String,
    placeholder: String
  },
  data () {
    return {
      inputType: 'password',
      visible: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    togglePasswordVisibility () {
      if (this.visible) {
        this.visible = false
        this.inputType = 'password'
      } else {
        this.visible = true
        this.inputType = 'text'
      }
    },
    correctError (errorMessage) {
      switch (errorMessage) {
        case 'required':
          return 'Enter password'
        case 'minLength':
          return 'Strong passwords have at least 6 characters'
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .password-control {
    &:before {
      position: absolute;
      content: '';
      left: 18px;
      top: 22px;
      width: 11px;
      height: 13px;
      background: url('../../../assets/form/password.svg');
      background-size: cover;
      z-index: 2;
    }
  }
  .togglePasswordVisibility {
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 20px;
    padding: 0 3px;

    svg{
      width: 16px;
      height: 16px;
      color: #848194;
    }
  }
</style>
