export function useHelper() {
  const splitCamelCase = (str) => {
    if (!str) return ''
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (firstChar) => firstChar.toUpperCase())
  }

  const escapeString = (value) => {
    if (typeof value === 'string') {
      value = value.replace(/"/g, '""')
      // Wrap in double quotes if value contains commas, new lines, or quotes
      if (/[",\n]/.test(value)) {
        value = `"${value}"`
      }
    }
    return value
  }

  return { splitCamelCase, escapeString }
}
