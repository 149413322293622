<template>
  <td class="align-middle" :class="getPotentialLossBgClass(price)">${{ Number(price) / 100 }}</td>
</template>

<script>
export default {
  props: {
    price: {
      type: Number,
      required: true
    }
  },
  methods: {
    getPotentialLossBgClass(price) {
      if (price < 30) return 'bgCustomSuccess'
      if (price >= 30 && price < 100) return 'bgCustomWarning'
      if (price > 100) return 'bgCustomDanger'
    }
  }
}
</script>
