<template>
  <b-modal v-model="show" centered title="Assign reports review status" hide-footer>
    <div class="d-block mx-2">
      <b-form-select v-model="status" :options="reviewStatuses" class="mt-2 mb-4">
        <template #first>
          <b-form-select-option :value="null" disabled>-- Select review status --</b-form-select-option>
        </template>
      </b-form-select>
    </div>
  </b-modal>
</template>

<script>
import { useReport } from '@/use/report'
import { reviewStatuses } from '@/constants/reportsReviewStatuses'

export default {
  emits: ['hideAssignReviewStatusModal', 'fetchReportsAfterStatusUpdate'],
  props: {
    showAssignReviewStatusModal: {
      type: Boolean,
      default: false
    },
    checkedReports: {
      default: new Map()
    }
  },
  data: () => {
    return {
      show: false,
      status: '',
      reviewStatuses: reviewStatuses.slice(1)
    }
  },
  watch: {
    showAssignReviewStatusModal() {
      this.show = this.$props.showAssignReviewStatusModal
    },
    show(val) {
      if (!val) {
        this.status = ''
        this.$emit('hideAssignReviewStatusModal')
      }
    },
    status(newStatus) {
      if (newStatus) this.assignStatus()
    }
  },
  setup() {
    const { updateReviewStatus } = useReport()
    return { updateReviewStatus }
  },
  methods: {
    async assignStatus() {
      const reportsIds = Array.from(this.checkedReports.keys())
      if (reportsIds.length > 0 && this.status) {
        const resp = await this.updateReviewStatus(this.status, reportsIds)
        if (resp && resp.result && resp.result === 'success') {
          this.show = false
          this.$emit('fetchReportsAfterStatusUpdate')
        }
      }
    }
  }
}
</script>
