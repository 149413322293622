<template>
  <td class="align-middle noBreak" :class="getDiscountBgClass(user.discount_id)">
    {{ user.discount_id + ' - ' + user.discount_name }}
  </td>
</template>

<script>
export default {
  props: {
    user: {
      required: true
    }
  },
  methods: {
    getDiscountBgClass(discountId) {
      switch (discountId) {
        case 1:
          return 'bgDiscountStandard'
        case 2:
          return 'bgDiscountPremium'
        case 3:
          return 'bgDiscountBusiness'
        case 4:
          return 'bgDiscountEnterprise'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bgDiscountStandard {
  background-color: #f3f3f3;
}
.bgDiscountPremium {
  background-color: #fff2cc;
}
.bgDiscountBusiness {
  background-color: #ffd966;
}
.bgDiscountEnterprise {
  background-color: #f1c232;
}
</style>
