import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import search from '@/store/modules/search'
import loaders from '@/store/modules/loaders'
import alert from '@/store/modules/alert'
import common from '@/store/modules/common'

export default createStore({
  modules: {
    auth,
    search,
    loaders,
    alert,
    common
  }
})
