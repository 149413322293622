<template>
  <div v-if="alerts.length > 0" class="alert">
    <ul class="alert__list">
      <transition-group name="list">
      <li
        v-for="alert in alerts"
        :key="alert.name ? alert.name : alert.id"
        class="alert__item"
        :class="{
          'alert__item--success': alert.type === 'success',
          'alert__item--error': alert.type === 'error',
        }"
      >
        {{ alert.text }}
        <div @click="remove(alert.id)" class="closeBtn" />
      </li>
      </transition-group>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    alerts () {
      return this.$store.state.alert.alerts
    }
  },
  methods: {
    remove (id) {
      this.$store.commit('alert/remove', id)
    },
    closeAlert (id) {
      this.remove(id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert {
    position: fixed;
    left: calc(50% + 350px);
    top: 50px;
    width: 284px;
    z-index: 1100;

    &__item {
      cursor: pointer;
      margin-bottom: 32px;
      position: relative;
      padding: 15px 32px 15px 20px;
      background: #FA8231;
      border-radius: 8px;
      font-size: 15px;
      color: #fff;
      width: 284px;
      list-style: none;

      &--success {
        background:#47B01A;
      }

      &--error {
        background:#FF4757;;
      }

      .closeBtn {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 13px;
        right: 10px;
        background: url('../assets/icons/close-white.svg') no-repeat center;
      }
    }
  }

  @media (max-width: 1320px) {
    .alert {
      left: auto;
      right: 25px;
    }
  }

  @media (max-width: 590px) {
    .alert {
      top: 40px;
      left: 25px;
      width: calc(100% - 50px);

      &__item {
        margin-bottom: 24px;
        width: 100%;
      }
    }
  }
</style>
