<template>
  <ul>
    <li v-for="item in items" :key="item.id">
      <LoginItem
        @click="$emit('socialLogin', item.provider)"
        :id="item.id"
        :text="item.text"
        :imgName="item.imgName"
        :imgAlt="item.provider"
      />
    </li>
  </ul>
</template>

<script>
import LoginItem from '@/components/Login/LoginBySocials/LoginItem.vue'

export default {
  components: {
    LoginItem
  },
  props: {
    items: Array
  },
  emits: ['socialLogin']
}
</script>

<style lang="scss" scoped>
  ul{
    margin-top: 32px;
    margin-bottom: 27px;
    padding-left: 0;
    li{
      list-style: none;
    }
  }
</style>
