<template>
  <td class="align-middle" :class="getReportActRatioBgClass(user.reports_ratio)">{{ user.reports_ratio ?? 0 }}%</td>
</template>

<script>
export default {
  props: {
    user: {
      required: true
    }
  },
  methods: {
    getReportActRatioBgClass(ratio) {
      if (!ratio) return 0
      if (ratio < 10) return 'bgCustomSuccess'
      if (ratio >= 10 && ratio < 30) return 'bgCustomWarning'
      if (ratio > 30) return 'bgCustomDanger'
    }
  }
}
</script>
