import { format } from 'date-fns'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { useHelper } from '@/use/helper'
import { useStore } from 'vuex'

export function useReport() {
  const store = useStore()
  const { initApiInst, actReportsApi } = useActAdminApi()
  const { handleCommonErrors } = useErrorHandler()
  const { escapeString } = useHelper()

  const getReports = async (params) => {
    try {
      await initApiInst()
      return await actReportsApi.value.getReports(params)
    } catch (e) {
      await handleCommonErrors(e)
      return null
    }
  }

  const getReport = async (reportId) => {
    try {
      await initApiInst()
      return await actReportsApi.value.getReport(reportId)
    } catch (e) {
      await handleCommonErrors(e)
      return null
    }
  }

  const updateReviewStatus = async (status, reportsIds) => {
    try {
      await initApiInst()
      const resp = await actReportsApi.value.changeReportsReviewStatus({
        changeReportsReviewStatusRequest: {
          review_status: status,
          reports_ids: reportsIds
        }
      })
      if (resp && resp.result && resp.result === 'success') {
        store.commit('alert/add', {
          id: Date.now(),
          text: 'Reports review statuses updated successfully',
          timeout: 3000,
          type: 'success',
          name: 'success_update_review_status'
        })
        return resp
      }
    } catch (e) {
      await handleCommonErrors(e)
      return null
    }
  }

  const getStatusColorClass = (status) => {
    switch (status) {
      case 'inReview':
        return 'text-primary'
      case 'declined':
        return 'text-warning'
      case 'approved':
        return 'text-success'
      default:
        return ''
    }
  }

  const getReviewStatusColorClass = (status) => {
    switch (status) {
      case 'pendingManualReview':
        return 'text-primary'
      case 'refundRequested':
        return 'text-info'
      case 'declinedManually':
      case 'declinedProvider':
        return 'text-warning'
      case 'approvedManually':
      case 'approvedProvider':
        return 'text-success'
      default:
        return ''
    }
  }

  const getReportReasonNameById = (id) => {
    switch (id) {
      case 1:
        return 'SMS is not coming'
      case 2:
        return 'Wrong SMS received'
      case 3:
        return 'Service not accepting the number'
      case 4:
        return 'Number has been used before'
      case 5:
        return 'Blocked after activation'
      case 6:
        return 'SMS received too late'
      case 7:
        return 'Other reason'
      default:
        return ''
    }
  }

  const getCsvData = (reports) => {
    const reportsArray = Array.isArray(reports) ? reports : [reports]
    const headers = [
      'Date',
      'Number',
      'Activation ID',
      'Service',
      'Country',
      'Reason',
      'Explanation',
      'Evidence Screenshots'
    ]
    const reportsData = reportsArray.map((report) => [
      format(new Date(report.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') || '',
      report.activation.phone || '',
      report.activation.provider_activation_id || '',
      report.activation.provider_service_id || '',
      report.activation.provider_country_id || '',
      getReportReasonNameById(report.reason_id) || '',
      escapeString(report.message || ''),
      report.evidence_url || ''
    ])

    return `${headers.join(',')}\n${reportsData.map((row) => row.join(',')).join('\n')}`
  }

  const getJsonData = (reports) => {
    const formatJsonReport = (report) => ({
      'Date': format(new Date(report.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') || '',
      'Number': report.activation.phone || '',
      'Activation ID': report.activation.provider_activation_id || '',
      'Service': report.activation.provider_service_id || '',
      'Country': report.activation.provider_country_id || '',
      'Reason': getReportReasonNameById(report.reason_id) || '',
      'Explanation': report.message || '',
      'Evidence Screenshots': report.evidence_url || ''
    })

    const reportsData = Array.isArray(reports) ? reports.map(formatJsonReport) : formatJsonReport(reports)

    return JSON.stringify(reportsData, null, 2)
  }

  return {
    getReports,
    getReport,
    updateReviewStatus,
    getStatusColorClass,
    getReviewStatusColorClass,
    getReportReasonNameById,
    getCsvData,
    getJsonData
  }
}
