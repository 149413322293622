<template>
  <b-modal v-model="show" centered title="Payout details" hide-footer>
    <div class="d-block mx-2">
      <b-form @submit.prevent="save">
        <div class="mb-3">
          <b-form-textarea v-model="details" placeholder="Enter payout details..." rows="6" required />
        </div>

        <div class="d-flex justify-content-center mt-3 mb-1">
          <b-button type="submit" variant="primary">
            {{ detailsData ? 'Save' : 'Add' }}
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css'
import { useActAdminApi } from '@/use/actAdminApi'

export default {
  emits: ['hideModal', 'detailsSaved'],
  props: {
    showModal: Boolean,
    detailsData: String,
    payoutId: Number
  },
  data: () => {
    return {
      show: false,
      details: ''
    }
  },
  watch: {
    showModal() {
      this.show = this.$props.showModal
    },
    show(val) {
      if (!val) {
        this.$emit('hideModal')
        this.details = ''
      } else {
        this.details = this.detailsData
      }
    }
  },
  setup() {
    const { initApiInst, actResellersApi } = useActAdminApi()
    return { initApiInst, actResellersApi }
  },
  methods: {
    async save() {
      try {
        await this.initApiInst()
        const opts = { service2: { details: this.details } }
        const resp = await this.actResellersApi.updateResellerPayoutDetails(this.payoutId, opts)
        if (resp.result && resp.result === 'success') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Payout details successfully saved',
            timeout: 3000,
            type: 'success',
            name: 'success_payout_details_saved'
          })
        }
        this.$emit('detailsSaved')
      } catch (e) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Payout details saving error! Try again later',
          timeout: 3000,
          type: 'error',
          name: 'error_payout_details_save'
        })
      }
    }
  }
}
</script>
