<template>
  <div class="modalBlock mt-4">
    <b-tabs content-class="mt-3" justified small>
      <b-tab title="Current Status" active>
        <div class="itemLine">
          <div class="name">Activation Status</div>
          <div class="value">{{ splitCamelCase(activation.activation_status) }}</div>
        </div>
        <div class="itemLine">
          <div class="name">Billing Status</div>
          <div class="value">{{ splitCamelCase(activation.billing_status) }}</div>
        </div>
        <div class="itemLine">
          <div class="name">SMS Status</div>
          <div class="value">{{ splitCamelCase(activation.sms_status) }}</div>
        </div>
        <div
          @click="hiddenFullMessage = !hiddenFullMessage"
          class="itemLine"
          :class="{ cursorPointer: activation.sms_text }"
        >
          <div class="name">Full Message</div>
          <div class="value">
            <span v-if="activation.sms_text">
              <BIconChevronRight v-if="hiddenFullMessage" />
              <BIconChevronDown v-else />
            </span>
            <span v-else class="text-black-50">Not provided</span>
          </div>
        </div>
        <div v-if="activation.sms_text && !hiddenFullMessage" class="itemLine">
          <div class="innerValue">{{ activation.sms_text }}</div>
        </div>
        <div class="itemLine">
          <div class="name">SMS Code</div>
          <div class="value">
            <span v-if="activation.sms_code">{{ activation.sms_code }}</span>
            <span v-else class="text-black-50">Not provided</span>
          </div>
        </div>
      </b-tab>
      <b-tab title="Reported Status">
        <div class="itemLine">
          <div class="name">Activation Status</div>
          <div class="value">{{ splitCamelCase(activationStateSnapshot.activation_status) }}</div>
        </div>
        <div class="itemLine">
          <div class="name">Billing Status</div>
          <div class="value">{{ splitCamelCase(activationStateSnapshot.billing_status) }}</div>
        </div>
        <div class="itemLine">
          <div class="name">SMS Status</div>
          <div class="value">{{ splitCamelCase(activationStateSnapshot.sms_status) }}</div>
        </div>
        <div
          @click="hiddenSnapshotFullMessage = !hiddenSnapshotFullMessage"
          class="itemLine"
          :class="{ cursorPointer: activationStateSnapshot.sms_text }"
        >
          <div class="name">Full Message</div>
          <div class="value">
            <span v-if="activationStateSnapshot.sms_text">
              <BIconChevronRight v-if="hiddenSnapshotFullMessage" />
              <BIconChevronDown v-else />
            </span>
            <span v-else class="text-black-50">Not provided</span>
          </div>
        </div>
        <div v-if="activationStateSnapshot.sms_text && !hiddenSnapshotFullMessage" class="itemLine">
          <div class="innerValue">{{ activationStateSnapshot.sms_text }}</div>
        </div>
        <div class="itemLine">
          <div class="name">SMS Code</div>
          <div class="value">
            <span v-if="activationStateSnapshot.sms_code">{{ activationStateSnapshot.sms_code }}</span>
            <span v-else class="text-black-50">Not provided</span>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BIconChevronDown, BIconChevronRight } from 'bootstrap-icons-vue'
import { useHelper } from '@/use/helper'

export default {
  components: { BIconChevronRight, BIconChevronDown },
  props: {
    activation: {
      required: true
    },
    activationStateSnapshot: {
      required: true
    }
  },
  data() {
    return {
      hiddenFullMessage: true,
      hiddenSnapshotFullMessage: true
    }
  },
  setup() {
    const { splitCamelCase } = useHelper()
    return { splitCamelCase }
  }
}
</script>
