<template>
  <td class="align-middle cursorPointer">
    <div @click="$router.push({ name: 'Home', query: { user_id: fbUid } })" class="ellipsisUserId">{{ fbUid }}</div>
  </td>
</template>

<script>
export default {
  props: {
    fbUid: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.ellipsisUserId {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70px;
}
</style>
