<template>
  <b-modal v-model="show" centered title="Add financial record" hide-footer>
    <div class="d-block mx-2">
      <b-form @submit.prevent="createFinanceRecord">
        <div class="mb-3">
          <label>Paid amount (in USD):</label>
          <b-form-input v-model="usdAmount" type="number" step="0.01" required />
        </div>
        <div class="mb-3">
          <label>Provider balance before last purchase<br />(in provider currency):</label>
          <b-form-input v-model="oldBalance" type="number" step="0.01" required />
        </div>
        <div class="mb-3">
          <label>Current provider balance after last purchase<br />(in provider currency):</label>
          <b-form-input v-model="newBalance" type="number" step="0.01" required />
        </div>
        <div class="mb-3">
          <label>Provider:</label>
          <b-form-select v-model="providerId" :options="providers" required />
        </div>
        <div class="mb-3">
          <label>Datetime:</label>
          <VueDatePicker
            :model-value="dateTime"
            @update:model-value="handleDateTimeChange"
            format="yyyy-MM-dd HH:mm"
            :clearable="false"
            required
            :teleport="true"
            teleport-center
          />
        </div>

        <div class="d-flex justify-content-center mt-3 mb-3">
          <b-button type="submit" variant="primary">Save</b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { useActAdminApi } from '@/use/actAdminApi'

export default {
  components: { VueDatePicker },
  emits: ['hideModal', 'financeRecordSaved'],
  props: {
    showModal: Boolean,
    providers: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      show: false,
      usdAmount: '',
      oldBalance: '',
      newBalance: '',
      providerId: '',
      dateTime: null
    }
  },
  watch: {
    showModal() {
      this.show = this.$props.showModal
    },
    show(val) {
      if (!val) {
        this.$emit('hideModal')
        this.usdAmount = this.oldBalance = this.newBalance = this.providerId = ''
      } else {
        this.dateTime = new Date()
      }
    }
  },
  setup() {
    const { initApiInst, actFinancesApi } = useActAdminApi()
    return { initApiInst, actFinancesApi }
  },
  methods: {
    async createFinanceRecord() {
      try {
        const timestamp = Math.floor(new Date(this.dateTime).getTime() / 1000)
        const financeRecord = {
          timestamp,
          usd_amount: this.usdAmount,
          old_balance: this.oldBalance,
          new_balance: this.newBalance,
          provider_id: this.providerId
        }
        await this.initApiInst()
        const resp = await this.actFinancesApi.createFinanceRecord({ financeRecord })
        if (resp.result && resp.result === 'success') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Finance record successfully created',
            timeout: 3000,
            type: 'success',
            name: 'success_finance_record_created'
          })
        }
        this.$emit('financeRecordSaved')
      } catch (e) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Finance record saving error! Try again later',
          timeout: 3000,
          type: 'error',
          name: 'error_finance_record_create'
        })
      }
    },
    handleDateTimeChange(val) {
      this.dateTime = new Date(val).getTime()
    }
  }
}
</script>
