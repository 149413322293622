import { getAuth } from 'firebase/auth'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export function useAuth() {
  const store = useStore()
  const router = useRouter()

  const logout = async () => {
    const auth = getAuth()
    await auth.signOut()
    store.commit('search/setSearchData', null)
    await router.push({ name: 'Login' })
  }

  const isSuperAdmin = () => {
    const auth = getAuth()
    const email = auth.currentUser.email ?? ''
    let superAdmins = []
    try {
      superAdmins = process.env.VUE_APP_SUPER_ADMINS ? JSON.parse(process.env.VUE_APP_SUPER_ADMINS) : []
    } catch (e) {}
    return superAdmins.findIndex((item) => item === email) !== -1
  }

  return { logout, isSuperAdmin }
}
