export default {
  namespaced: true,
  state: {
    searchLoading: false,
    commonLoading: false
  },
  mutations: {
    setSearchLoading: (state, val) => {
      state.searchLoading = val
    },
    setCommonLoading: (state, val) => {
      state.commonLoading = val
    }
  }
}
