<template>
  <button :style="{width: width, height: height}" class="btn" :class="{'btn__fill': fill}" :disabled="loading">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    fill: Boolean,
    width: String,
    height: String,
    loading: Boolean
  }
}
</script>

<style scoped lang="scss">
  .btn {
    border-radius: 12px;
    border: 1px solid #FA8231;
    background: #FFF;
    color: #FA8231;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 2px solid #FA8231;
    }

    &:active {
      opacity: 0.8;
      background: #FA8231;
      color: #FFF;
    }

    &__fill {
      background: #FA8231;
      color: #FFF;

      &:hover {
        box-shadow: 0 4px 4px 0 rgba(34, 60, 80, 0.2);
        border: 1px solid #FA8231;
        color: #FFF;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
</style>
