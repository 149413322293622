<template>
  <b-row class="mb-3 align-items-center">
    <b-col sm="2">Filter by wallet:</b-col>
    <b-col sm="4"><b-form-input v-model="walletQueryStr" placeholder="Enter wallet address" /></b-col>
    <b-col sm="4" v-if="walletQueryStr">
      <b-button @click="getPayouts()" variant="outline-info" class="mr-1">Search</b-button>
      <b-button @click="clearSearch()" variant="outline-warning">Clear</b-button>
    </b-col>
  </b-row>
  <b-row class="mb-3 align-items-center">
    <b-col sm="2">Status:</b-col>
    <b-col sm="10">
      <b-form-checkbox-group id="checkbox-group-status" v-model="statuses" :options="statusesOptions" name="status" />
    </b-col>
  </b-row>
  <b-row class="mb-4 align-items-center">
    <b-col sm="2">Payment method:</b-col>
    <b-col sm="10">
      <b-form-checkbox-group id="checkbox-group-gateway" v-model="gateways" :options="gatewaysOptions" name="gateway" />
    </b-col>
  </b-row>
  <b-row>
    <b-col class="mb-5">
      <transition name="fade" mode="out-in">
        <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
          <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
        </div>
        <div v-else>
          <b-table responsive hover :fields="fields" :items="payouts">
            <template #cell(user_id)="data">
              <b-button @click="redirectToUser(data.item.user_fb_uid)" class="noDecoration" variant="link">
                {{ data.item.user_id }}
              </b-button>
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at ? format(new Date(data.item.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') : '' }}
            </template>
            <template #cell(id)="data">{{ data.item.id }} ({{ data.item.hash_id }})</template>
            <template #cell(gateway)="data">{{
              data.item.gateway === 'usdt_trx20'
                ? 'USDT (TRX20)'
                : data.item.gateway === 'usdt_bep20'
                ? 'USDT (BEP20)'
                : data.item.gateway === 'usdt_erc20'
                ? 'USDT (ERC20)'
                : data.item.gateway.charAt(0).toUpperCase() + data.item.gateway.slice(1)
            }}</template>
            <template #cell(wallet)="data">
              <div
                @click="copyToCb($refs['payout' + data.item.id], data.item.wallet)"
                :ref="`payout${data.item.id}`"
                :title="data.item.wallet"
                class="ellipsis"
              >
                {{ data.item.wallet }}
              </div>
            </template>
            <template #cell(amount)="data">${{ data.item.amount / 100 }}</template>
            <template #cell(fee)="data">${{ data.item.fee / 100 }}</template>
            <template #cell(amount_to_pay)="data">
              <div
                @click="copyToCb($refs['payout_amount_to_pay' + data.item.id], data.item.amount_to_pay / 100)"
                class="cursorPointer"
              >
                $<span :ref="`payout_amount_to_pay${data.item.id}`">{{ data.item.amount_to_pay / 100 }}</span>
              </div>
            </template>
            <template #cell(details)="data">
              <b-button
                @click="showDetailsModal(data.item)"
                :variant="!data.item.details ? 'outline-primary' : 'outline-info'"
              >
                {{ data.item.details ? 'Show' : 'Add' }}
              </b-button>
            </template>
            <template #cell(status)="data">
              <PayoutStatusSelect :id="data.item.id" :status="data.item.status" />
            </template>
          </b-table>
          <Paginator
            @next="showNextPage"
            @prev="showPrevPage"
            @selectPage="selectPage"
            :pagesCount="this.pagesCount"
            :currentPage="this.currentPage"
            :limit="this.limit"
            :itemsCount="this.itemsCount"
            class="pagination mt-4 mb-4"
          />
        </div>
      </transition>
    </b-col>
  </b-row>
  <ResellerPayoutDetailsModal
    :showModal="showModal"
    :payout-id="currentPayout?.id"
    :details-data="currentPayout?.details"
    @detailsSaved="handleDetailsSaved"
    @hideModal="hideModal"
  />
</template>

<script>
import { format } from 'date-fns'
import { useActAdminApi } from '@/use/actAdminApi'
import { useSearch } from '@/use/search'
import ResellerPayoutDetailsModal from '@/components/Reseller/PayoutDetailsModal'
import Paginator from '@/components/Paginator.vue'
import PayoutStatusSelect from '@/components/Reseller/PayoutStatusSelect.vue'
import { useAuth } from '@/use/auth'
import { useCopy } from '@/use/copy'

export default {
  components: { ResellerPayoutDetailsModal, Paginator, PayoutStatusSelect },
  emits: ['toggleModal'],
  data: () => {
    return {
      format,
      loading: true,
      showModal: false,
      payouts: [],
      fields: [
        { key: 'user_id', label: 'Account ID', tdClass: 'align-middle' },
        { key: 'id', label: 'Payout ID (HashId)', tdClass: 'align-middle' },
        { key: 'created_at', label: 'Date', tdClass: 'align-middle' },
        { key: 'gateway', label: 'Payment Method', tdClass: 'align-middle' },
        { key: 'wallet', label: 'Wallet Details', tdClass: 'align-middle' },
        { key: 'amount', label: 'Amount', tdClass: 'align-middle' },
        { key: 'fee', label: 'Fee', tdClass: 'align-middle' },
        { key: 'amount_to_pay', label: 'Amount to Pay', tdClass: 'align-middle' },
        { key: 'details', label: 'Details', tdClass: 'align-middle' },
        { key: 'status', label: 'Status', tdClass: 'align-middle' }
      ],
      currentPage: 1,
      limit: 20,
      pagesCount: 1,
      itemsCount: null,
      statuses: [],
      gateways: [],
      walletQueryStr: '',
      currentPayout: null,
      statusesOptions: [
        { text: 'Paid', value: 'paid' },
        { text: 'Pending', value: 'pending' },
        { text: 'Canceled', value: 'canceled' }
      ],
      gatewaysOptions: [
        { text: 'USDT (TRX20)', value: 'usdt_trx20' },
        { text: 'USDT (BEP20)', value: 'usdt_bep20' },
        { text: 'USDT (ERC20)', value: 'usdt_erc20' },
        { text: 'Paypal', value: 'paypal' }
      ]
    }
  },
  setup() {
    const { initApiInst, actResellersApi } = useActAdminApi()
    const { isSuperAdmin } = useAuth()
    const { copyToCb } = useCopy()
    const { getSearchData } = useSearch()
    return { initApiInst, actResellersApi, isSuperAdmin, copyToCb, getSearchData }
  },
  mounted() {
    if (!this.isSuperAdmin()) {
      return this.$router.push({ name: 'Home' })
    }
    this.getPayouts()
  },
  watch: {
    statuses() {
      this.getPayouts()
    },
    gateways() {
      this.getPayouts()
    }
  },
  methods: {
    async getPayouts() {
      this.loading = true
      const page = Number(this.$route.query.page ?? 1)
      const reqData = { page }
      if (this.walletQueryStr) reqData.wallet = this.walletQueryStr
      if (this.statuses.length > 0) reqData.status = this.statuses
      if (this.gateways.length > 0) reqData.gateway = this.gateways
      try {
        await this.initApiInst()
        const resp = await this.actResellersApi.getResellersPayouts(reqData)
        if (resp && resp.payouts) {
          this.payouts = resp.payouts
          this.currentPage = resp.page
          this.limit = resp.limit
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
        }
      } catch (e) {
        this.payouts = []
      } finally {
        this.loading = false
      }
    },
    async getByPage(page) {
      await this.$router.push({ query: { ...this.$route.query, page } })
      await this.getPayouts()
    },
    async showNextPage() {
      await this.getByPage(this.currentPage + 1)
    },
    async showPrevPage() {
      await this.getByPage(this.currentPage - 1)
    },
    async selectPage(page) {
      await this.getByPage(page)
    },
    showDetailsModal(payout) {
      this.showModal = true
      this.currentPayout = payout
    },
    hideModal() {
      this.showModal = false
    },
    async handleDetailsSaved() {
      this.hideModal()
      await this.getPayouts()
    },
    redirectToUser(fbUid) {
      this.getSearchData(fbUid)
      this.$router.push({ path: '/' })
    },
    clearSearch() {
      this.walletQueryStr = ''
      this.getPayouts()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/animations/fade-data.scss';

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  display: block;
  cursor: pointer;
}
.noDecoration {
  text-decoration: none;
}
.cursorPointer {
  cursor: pointer;
}
.mr-1 {
  margin-right: 8px;
}
</style>
