<template>
  <td class="align-middle" :class="getReportApproveRatioBgClass(user.approved_reports_ratio)">
    {{ user.approved_reports_ratio ?? 0 }}%
  </td>
</template>

<script>
export default {
  props: {
    user: {
      required: true
    }
  },
  methods: {
    getReportApproveRatioBgClass(ratio) {
      if (!ratio) return 0
      if (ratio >= 80) return 'bgCustomSuccess'
      if (ratio >= 30 && ratio < 80) return 'bgCustomWarning'
      if (ratio < 30) return 'bgCustomDanger'
    }
  }
}
</script>
