<template>
  <td class="align-middle">{{ format(new Date(date * 1000), 'yyyy-MM-dd HH:mm:ss') }}</td>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    date: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      format
    }
  }
}
</script>
