<template>
  <td class="align-middle">
    <b-button @click="copyJson" variant="outline-secondary" size="sm">Copy (JSON)</b-button>
  </td>
</template>

<script>
import { useReport } from '@/use/report'
import { useCopy } from '@/use/copy'

export default {
  props: {
    report: {
      required: true
    }
  },
  setup() {
    const { getReportReasonNameById, getJsonData } = useReport()
    const { copyToCb } = useCopy()
    return {
      getJsonData,
      getReportReasonNameById,
      copyToCb
    }
  },
  methods: {
    copyJson() {
      const jsonData = this.getJsonData(this.report)
      this.copyToCb(null, jsonData)
    }
  }
}
</script>
