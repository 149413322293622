<template>
  <b-modal v-model="show" centered title="Report Details" hide-footer>
    <transition name="fade" mode="out-in">
      <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
        <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
      </div>
      <div v-else>
        <div v-if="reportData" class="d-block mx-2 viewReportModal" ref="container">
          <div v-if="!infoModal" class="actionsBlock">
            <div class="mt-1">
              <b-form-checkbox
                @change="$emit('changeCheckboxState', reportData.activation.activation_id)"
                :checked="checkedReports.has(reportData.activation.activation_id)"
                class="cursorPointer"
              >
                Select
              </b-form-checkbox>
            </div>
            <div class="copyButtonsBlock">
              <b-button @click="copyLine" variant="outline-secondary" size="sm">Copy Line</b-button>
              <b-button @click="copyJson" variant="outline-secondary" size="sm">Copy JSON</b-button>
            </div>
          </div>

          <div class="modalBlock">
            <div class="itemLine">
              <div class="name">Status</div>
              <div class="value" :class="getStatusColorClass(reportData.activation.report_status)">
                {{ splitCamelCase(reportData.activation.report_status) }}
              </div>
            </div>
            <div v-if="!infoModal" class="itemLine">
              <div class="name">Review Status</div>
              <div class="value">
                <b-form-select
                  v-model="status"
                  :options="status === 'pendingManualReview' ? reviewStatuses : reviewStatuses.slice(1)"
                  size="sm"
                  class="customSelect"
                />
              </div>
            </div>
            <div class="itemLine">
              <div class="name">Created At</div>
              <div class="value">{{ format(new Date(reportData.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') }}</div>
            </div>
            <div class="itemLine">
              <div class="name">Report Reason</div>
              <div class="value">{{ getReportReasonNameById(reportData.reason_id) }}</div>
            </div>
            <div @click="toggleExplMessage" class="itemLine" :class="{ cursorPointer: reportData.message }">
              <div class="name">Explanation Message</div>
              <div class="value">
                <span v-if="reportData.message">
                  <BIconChevronRight v-if="hiddenExplMessage" />
                  <BIconChevronDown v-else />
                </span>
                <span v-else class="text-black-50">Not provided</span>
              </div>
            </div>
            <div v-if="reportData.message && !hiddenExplMessage" class="itemLine">
              <div class="innerValue">{{ reportData.message }}</div>
            </div>
            <div class="itemLine">
              <div class="name">Evidence Submitted</div>
              <div class="value">
                <b-button
                  v-if="reportData.evidence_url"
                  @click="openEvidenceUrl(reportData.evidence_url)"
                  variant="outline-secondary"
                  size="sm"
                  class="viewEvidenceButton"
                >
                  View
                </b-button>
                <span v-else class="text-black-50">Not provided</span>
              </div>
            </div>
          </div>

          <ActivationData :activation="reportData.activation" />
          <ActivationStatusData
            :activation="reportData.activation"
            :activation-state-snapshot="JSON.parse(reportData.activation_state_snapshot)"
          />
        </div>
      </div>
    </transition>
  </b-modal>
</template>

<script>
import { format } from 'date-fns'
import { useReport } from '@/use/report'
import { useHelper } from '@/use/helper'
import { useCopy } from '@/use/copy'
import { reviewStatuses } from '@/constants/reportsReviewStatuses'
import { BIconChevronRight, BIconChevronDown } from 'bootstrap-icons-vue'
import { BButton } from 'bootstrap-vue-3'
import ActivationData from '@/components/Reports/ViewReportModal/ActivationData.vue'
import ActivationStatusData from '@/components/Reports/ViewReportModal/ActivationStatusData.vue'

export default {
  components: {
    ActivationStatusData,
    ActivationData,
    BButton,
    BIconChevronRight,
    BIconChevronDown
  },
  emits: ['closeViewReportModal', 'changeCheckboxState', 'fetchReportsAfterStatusUpdate'],
  props: {
    showViewReportModal: {
      type: Boolean,
      default: false
    },
    viewedReport: {
      default: null
    },
    checkedReports: {
      default: new Map()
    },
    infoModal: {
      default: false
    },
    viewedReportId: {
      default: null
    }
  },
  data: () => {
    return {
      format,
      show: false,
      reportData: null,
      status: '',
      reviewStatuses,
      hiddenExplMessage: true,
      loading: false
    }
  },
  watch: {
    showViewReportModal() {
      this.show = this.$props.showViewReportModal
    },
    viewedReport(val) {
      if (val) {
        this.reportData = val
        this.status = this.reportData?.activation?.report_review_status
      }
    },
    show(val) {
      if (!val) {
        this.$emit('closeViewReportModal')
        this.reportData = null
        this.status = ''
      }
    },
    status(newStatus, oldStatus) {
      if (newStatus && oldStatus && newStatus !== oldStatus) this.assignStatus()
    },
    viewedReportId(val) {
      if (val) {
        this.getReportById(val)
      }
    }
  },
  setup() {
    const { updateReviewStatus, getReport, getStatusColorClass, getReportReasonNameById, getCsvData, getJsonData } =
      useReport()
    const { splitCamelCase } = useHelper()
    const { copyToCb } = useCopy()
    return {
      splitCamelCase,
      updateReviewStatus,
      getReport,
      getStatusColorClass,
      getReportReasonNameById,
      getCsvData,
      getJsonData,
      copyToCb
    }
  },
  methods: {
    toggleExplMessage() {
      this.hiddenExplMessage = !this.hiddenExplMessage
    },
    openEvidenceUrl(url) {
      window.open(url, '_blank')
    },
    copyLine() {
      const csvString = this.getCsvData(this.reportData)
      this.copyToCb(null, csvString, this.$refs.container)
    },
    copyJson() {
      const jsonData = this.getJsonData(this.reportData)
      this.copyToCb(null, jsonData, this.$refs.container)
    },
    async assignStatus() {
      const reportId = this.reportData.activation.activation_id
      if (reportId && this.status) {
        const resp = await this.updateReviewStatus(this.status, [reportId])
        if (resp && resp.result && resp.result === 'success') {
          this.show = false
          this.$emit('fetchReportsAfterStatusUpdate')
        }
      }
    },
    async getReportById(reportId) {
      if (!reportId) return
      this.loading = true
      this.reportData = await this.getReport(reportId)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/animations/fade-data.scss';

.viewReportModal {
  .actionsBlock {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    .copyButtonsBlock {
      display: flex;
      gap: 14px;
    }
  }

  .reportDetailsBlockHeader {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 20px;
  }

  .modalBlock {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 5px 0;
    margin-bottom: 10px;

    .itemLine {
      display: flex;
      justify-content: space-between;
      padding: 9px 14px;
      border-bottom: 1px solid #e9ecef;
      font-size: 14px;

      &:last-child {
        border-bottom: none;
      }

      .name,
      .value {
        display: flex;
        align-items: center;
      }

      .innerValue {
        padding: 0 10px;
      }

      .viewEvidenceButton {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .customSelect:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
</style>
