<template>
  <Reports :show-header="false" :show-user-data="true" />
</template>

<script>
import Reports from '@/components/Reports/Index.vue'

export default {
  components: { Reports }
}
</script>
