import store from '../store'

export default (element, deleteTimeout = 1500) => {
  let range
  if (window.getSelection) {
    range = document.createRange()
    range.selectNode(element)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    const timeoutId = setTimeout(() => {
      window.getSelection().removeAllRanges()
      range = null
      store.commit('common/setCopiedRangeTimeoutId')
    }, deleteTimeout)
    store.commit('common/setCopiedRangeTimeoutId', timeoutId)
  }
}
