<template>
  <td class="align-middle">
    <b-button @click="copyReportLine" variant="outline-secondary" size="sm">Copy (Line)</b-button>
  </td>
</template>

<script>
import { useReport } from '@/use/report'
import { useCopy } from '@/use/copy'

export default {
  props: {
    report: {
      required: true
    }
  },
  setup() {
    const { getCsvData } = useReport()
    const { copyToCb } = useCopy()
    return {
      getCsvData,
      copyToCb
    }
  },
  methods: {
    copyReportLine() {
      const csvString = this.getCsvData(this.report)
      this.copyToCb(null, csvString)
    }
  }
}
</script>
