<template>
  <div class="reportDetailsBlockHeader">Activation Details</div>
  <div class="modalBlock" ref="detailsContainer">
    <b-tabs content-class="mt-3" justified small>
      <b-tab title="Activate" active>
        <div class="itemLine">
          <div class="name">Activation ID</div>
          <div
            @click="copyToCb($refs.activateActIdRef, activation.activation_id, $refs.detailsContainer)"
            class="value valueWithCopy cursorPointer"
          >
            <span ref="activateActIdRef">{{ activation.activation_id }}</span>
            <BIconCopy />
          </div>
        </div>
        <div class="itemLine">
          <div class="name">Created At</div>
          <div class="value">{{ format(new Date(activation.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') }}</div>
        </div>
        <div class="itemLine">
          <div class="name">Phone Number</div>
          <div
            @click="copyToCb($refs.activatePhoneRef, activation.phone, $refs.detailsContainer)"
            class="value valueWithCopy cursorPointer"
          >
            <div>
              +<span ref="activatePhoneRef">{{ activation.phone }}</span>
            </div>
            <BIconCopy />
          </div>
        </div>
        <div class="itemLine">
          <div class="name">Service</div>
          <div class="value">{{ activation.service_name }} ({{ activation.service_id }})</div>
        </div>
        <div class="itemLine">
          <div class="name">Country</div>
          <div class="value">{{ activation.country_name }} ({{ activation.country_id }})</div>
        </div>
        <div class="itemLine">
          <div class="name">Price</div>
          <div class="value">${{ activation.price / 100 }}</div>
        </div>
      </b-tab>
      <b-tab title="Provider">
        <div class="itemLine">
          <div class="name">Activation ID</div>
          <div
            @click="copyToCb($refs.activateProviderIdRef, activation.provider_activation_id, $refs.detailsContainer)"
            class="value valueWithCopy cursorPointer"
          >
            <span ref="activateProviderIdRef">{{ activation.provider_activation_id }}</span>
            <BIconCopy />
          </div>
        </div>
        <div class="itemLine">
          <div class="name">Created At</div>
          <div class="value">{{ format(new Date(activation.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') }}</div>
        </div>
        <div class="itemLine">
          <div class="name">Phone Number</div>
          <div
            @click="copyToCb($refs.activateProviderPhoneRef, activation.phone, $refs.detailsContainer)"
            class="value valueWithCopy cursorPointer"
          >
            <div>
              +<span ref="activateProviderPhoneRef">{{ activation.phone }}</span>
            </div>
            <BIconCopy />
          </div>
        </div>
        <div class="itemLine">
          <div class="name">Service</div>
          <div class="value">{{ activation.service_name }} ({{ activation.provider_service_id }})</div>
        </div>
        <div class="itemLine">
          <div class="name">Country</div>
          <div class="value">{{ activation.country_name }} ({{ activation.provider_country_id }})</div>
        </div>
        <div class="itemLine">
          <div class="name">Price</div>
          <div class="value">${{ activation.provider_price / 100 }}</div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { BIconCopy } from 'bootstrap-icons-vue'
import { useCopy } from '@/use/copy'

export default {
  components: { BIconCopy },
  props: {
    activation: {
      required: true
    }
  },
  data() {
    return {
      format
    }
  },
  setup() {
    const { copyToCb } = useCopy()
    return { copyToCb }
  }
}
</script>

<style lang="scss" scoped>
.valueWithCopy {
  display: flex;
  align-items: center;
  gap: 6px;
}
</style>
