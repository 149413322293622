<template>
  <b-alert v-model="showLoginError" variant="danger" dismissible>{{ loginErrorText }}</b-alert>

  <form @submit.prevent="login" class="form">
    <EmailControl
      :errorMessage="this.v$.email.$errors?.[0]?.$validator"
      v-model.trim="email"
      placeholder="Email"
      class="form__control"
    />
    <PasswordControl
      :errorMessage="this.v$.password.$errors?.[0]?.$validator"
      v-model.trim="password"
      placeholder="Password"
      class="form__control"
    />

    <LoginByEmailButton class="form__send" width="100%" height="58px" fill>Login with Email</LoginByEmailButton>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

import EmailControl from '@/components/Login/Controls/EmailControl.vue'
import PasswordControl from '@/components/Login/Controls/PasswordControl.vue'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import LoginByEmailButton from '@/components/Login/LoginByEmail/LoginByEmailButton'

export default {
  components: {
    LoginByEmailButton,
    EmailControl,
    PasswordControl
  },
  data () {
    return {
      email: '',
      password: '',
      loginErrorText: '',
      showLoginError: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      email: {
        email,
        required
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    async login () {
      this.showLoginError = false
      this.loginErrorText = ''
      const isValid = await this.v$.$validate()

      if (isValid) {
        try {
          const auth = getAuth()
          const result = await signInWithEmailAndPassword(auth, this.email, this.password)
          if (result && result.user) {
            await this.$router.push({ name: 'Home' })
          }
        } catch (e) {
          if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
            this.loginErrorText = 'Email address and password don\'t match'
          } else {
            this.loginErrorText = 'Something went wrong!'
          }
          this.showLoginError = true
        }
      }
    }
  }
}
</script>
