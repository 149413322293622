import slugify from 'slugify'

export function useService () {
  const createServiceIdFromName = (name) => {
    let serviceId = ''
    if (name) {
      serviceId = name.replace(/[&/\\#, +()$~%.'":*!;^№@?<>{}[\]]/g, '_')
      serviceId = slugify(serviceId, {
        replacement: '_',
        lower: true,
        strict: false,
        locale: 'en',
        trim: true
      })
    }
    return serviceId
  }

  return { createServiceIdFromName }
}
