import { getAuth } from 'firebase/auth'

export default {
  namespaced: true,
  state: {
    isAuthenticated: false
  },
  mutations: {
    setIsAuthenticated: (state, val) => {
      state.isAuthenticated = val
    }
  },
  actions: {
    setUserData ({ commit }) {
      const auth = getAuth()
      const user = auth.currentUser
      commit('setIsAuthenticated', !!user)
    }
  }
}
