<template>
  <thead>
    <tr>
      <th class="align-middle text-center">
        <div
          v-if="checkedReports.size > 0"
          @click="$emit('clearCheckedReports')"
          class="cursorPointer text-danger"
          title="Clear selected"
        >
          &#x2715;
        </div>
      </th>
      <th class="align-middle">Date Report</th>
      <th v-if="showUserData" class="align-middle">User ID</th>
      <th v-if="showUserData" class="align-middle">User Level</th>
      <th v-if="showUserData" class="align-middle">Report Activation Ratio</th>
      <th v-if="showUserData" class="align-middle">Report Approve Ratio</th>
      <th class="align-middle">Provider</th>
      <th class="align-middle">Service<br />(On Act)</th>
      <th class="align-middle">Country<br />(On Act)</th>
      <th class="align-middle">Price<br />(User paid to us)</th>
      <th class="align-middle">Cost<br />(Potential loss)</th>
      <th class="align-middle text-center">Review Status<br />(Manual)</th>
      <th class="align-middle text-center" colspan="3">Actions</th>
    </tr>
  </thead>
</template>

<script>
export default {
  emits: ['clearCheckedReports'],
  props: {
    showUserData: {
      type: Boolean,
      default: false
    },
    checkedReports: {
      default: new Map()
    }
  }
}
</script>
