<template>
  <b-row class="pt-1 mb-4">
    <b-col>
      <div class="start">
        <b-button @click="showAddModal" variant="primary">Add financial record</b-button>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="mb-5">
      <transition name="fade" mode="out-in">
        <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
          <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
        </div>
        <div v-else>
          <b-table responsive hover :fields="fields" :items="records">
            <template #cell(datetime)="data">
              {{ data.item.datetime ? format(new Date(data.item.datetime), 'yyyy-MM-dd HH:mm:ss') : '' }}
            </template>
            <template #cell(provider_id)="data">
              {{ data.item.provider_id ? providers.find((prv) => prv.value === data.item.provider_id).text : '' }}
            </template>
          </b-table>
          <Paginator
            @next="showNextPage"
            @prev="showPrevPage"
            @selectPage="selectPage"
            :pagesCount="this.pagesCount"
            :currentPage="this.currentPage"
            :limit="this.limit"
            :itemsCount="this.itemsCount"
            class="pagination mt-4 mb-4"
          />
        </div>
      </transition>
    </b-col>
  </b-row>
  <FinanceModal
    :showModal="showModal"
    :providers="providers"
    @financeRecordSaved="handleFinanceRecordSaved"
    @hideModal="hideModal"
  />
</template>

<script>
import { format } from 'date-fns'
import { useActAdminApi } from '@/use/actAdminApi'
import FinanceModal from '@/components/Finance/FinanceModal'
import Paginator from '@/components/Paginator.vue'
import { useMapping } from '@/use/mapping'
import { useAuth } from '@/use/auth'

export default {
  components: { FinanceModal, Paginator },
  emits: ['toggleModal'],
  data: () => {
    return {
      format,
      loading: true,
      showModal: false,
      records: [],
      fields: [
        'id',
        'datetime',
        { key: 'source_amount', label: 'Paid amount (USD)' },
        { key: 'destination_amount', label: 'Credited amount (provider currency)' },
        { key: 'provider_id', label: 'Provider' },
        'effective_exchange_rate'
      ],
      currentPage: 1,
      limit: 20,
      pagesCount: 1,
      itemsCount: null,
      providers: []
    }
  },
  setup() {
    const { initApiInst, actFinancesApi } = useActAdminApi()
    const { getProviders } = useMapping()
    const { isSuperAdmin } = useAuth()
    return { initApiInst, actFinancesApi, getProviders, isSuperAdmin }
  },
  mounted() {
    if (!this.isSuperAdmin()) {
      return this.$router.push({ name: 'Home' })
    }
    this.loadProviders()
    this.getFinances()
  },
  methods: {
    async loadProviders() {
      const providers = await this.getProviders(true)
      if (providers && providers.length > 0) {
        providers.shift()
        this.providers = providers
      }
    },
    async getFinances() {
      this.loading = true
      const page = Number(this.$route.query.page ?? 1)
      try {
        await this.initApiInst()
        const resp = await this.actFinancesApi.getFinances({ searchStr: this.searchStr, page })
        if (resp && resp.finance_records) {
          this.records = resp.finance_records
          this.currentPage = resp.page
          this.limit = resp.limit
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
        }
      } catch (e) {
        this.records = []
      } finally {
        this.loading = false
      }
    },
    async getByPage(page) {
      await this.$router.push({ query: { ...this.$route.query, page } })
      await this.getFinances()
    },
    async showNextPage() {
      await this.getByPage(this.currentPage + 1)
    },
    async showPrevPage() {
      await this.getByPage(this.currentPage - 1)
    },
    async selectPage(page) {
      await this.getByPage(page)
    },
    showAddModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
    },
    async handleFinanceRecordSaved() {
      this.hideModal()
      await this.getFinances()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/animations/fade-data.scss';
</style>
