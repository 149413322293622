<template>
  <div class="searchInputWrap">
    <input
      @keyup.enter="$emit('search')"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="searchText"
      :placeholder="placeholder"
      class="form-control"
    />
    <button v-if="!searchText" @click="$emit('search')" class="searchBtn">
      <BIconSearch class="searchIcon" />
    </button>
    <button v-else @click="$emit('clearSearch')" class="searchBtn">
      <BIconX class="clearSearchIcon" />
    </button>
  </div>
</template>

<script>
import { BIconSearch, BIconX } from 'bootstrap-icons-vue'

export default {
  components: { BIconSearch, BIconX },
  emits: ['update:modelValue', 'search', 'clearSearch'],
  props: {
    searchText: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => 'Search'
    }
  }
}
</script>

<style lang="scss" scoped>
.searchInputWrap {
  position: relative;
  input {
    padding-right: 32px;
  }
  .searchBtn {
    position: absolute;
    top: 4px;
    right: 1px;
    border: none;
    background: transparent;
    .searchIcon {
      width: 22px;
      height: 22px;
      fill: #b4b4b4;
    }
    .clearSearchIcon {
      width: 22px;
      height: 22px;
      fill: red;
    }
  }
}
</style>
