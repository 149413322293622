import { copyText } from 'vue3-clipboard'
import selectText from '@/helpers/select-text.js'
import { useStore } from 'vuex'

export function useCopy() {
  const store = useStore()

  const copyToCb = (ref, text, container = undefined) => {
    if (!text) return
    copyText(text, container, (error) => {
      if (!error) {
        if (ref) selectText(ref)
        store.commit('alert/add', {
          id: Date.now(),
          text: 'Copied to clipboard',
          type: 'success',
          timeout: 1500,
          name: 'copy'
        })
      }
    })
  }

  return { copyToCb }
}
