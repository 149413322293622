<template>
  <b-button @click="logout" variant="outline-warning">Logout</b-button>
</template>

<script>
import { useAuth } from '@/use/auth'

export default {
  setup () {
    const { logout } = useAuth()
    return { logout }
  }
}
</script>
