<template>
  <div class="statusSelect" tabindex="0" @blur="open = false">
    <div @click="clickSelectHandler" :class="{ open: open }" class="selected">
      <span :class="`${selected}Status`">{{ statusOptions[selected] }}</span>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(status, key) of statusOptions" :key="key" @click="confirmChangeStatus(key)" :class="`${key}Status`">
        {{ status }}
      </div>
    </div>
  </div>

  <b-modal
    v-model="changeStatusModal"
    @keydown.enter="changeStatus"
    title="Are you sure?"
    class="questionModal"
    centered
    hide-footer
  >
    <p>
      Change account status to <strong>{{ statusOptions[newStatus] }}?</strong>
    </p>
    <div class="btnBl">
      <b-button @click="closeModal" variant="light">Cancel</b-button>
      <b-button @click="changeStatus" :disabled="loading" variant="danger" class="confirmBtn">
        <b-spinner v-if="loading" small variant="light" />
        <span v-else>Change status</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { useSearch } from '@/use/search'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export default {
  props: {
    account: Object
  },
  data() {
    return {
      changeStatusModal: false,
      defaultStatusOptions: {
        enabled: 'Enabled',
        whiteListed: 'Whitelisted',
        onHold: 'On Hold'
      },
      statusOptions: {},
      deletedStatusOptions: {
        deleted: 'Deleted'
      },
      selected: null,
      open: false,
      newStatus: null
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    }
  },
  watch: {
    account() {
      this.formStatusSelect()
    }
  },
  mounted() {
    this.formStatusSelect()
  },
  setup() {
    const { getSearchData } = useSearch()
    const { initApiInst, actUserApi } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    return { getSearchData, initApiInst, actUserApi, handleCommonErrors }
  },
  methods: {
    formStatusSelect() {
      if (this.account.status === 'deleted') {
        this.statusOptions = this.deletedStatusOptions
      } else {
        this.statusOptions = this.defaultStatusOptions
      }
      this.selected = this.account.status
    },
    clickSelectHandler() {
      if (this.selected === 'deleted') return
      this.open = !this.open
    },
    confirmChangeStatus(status) {
      if (status === this.selected) {
        this.closeModal()
        return
      }
      this.changeStatusModal = true
      this.newStatus = status
    },
    async changeStatus() {
      try {
        this.$store.commit('loaders/setCommonLoading', true)
        await this.initApiInst()
        const resp = await this.actUserApi.changeStatus(this.account.id, this.newStatus)
        if (resp && resp.result === 'success') {
          this.selected = this.newStatus
          await this.getSearchData(this.account.fbuid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Account status changed successfully',
            timeout: 3000,
            type: 'success',
            name: 'status_changed'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.closeModal()
        this.$store.commit('loaders/setCommonLoading', false)
      }
    },
    closeModal() {
      this.open = false
      this.changeStatusModal = false
      this.newStatus = null
    }
  }
}
</script>

<style lang="scss" scoped>
.statusSelect {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 38px;
  line-height: 38px;

  .selected {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid rgba(25, 22, 50, 0.12);
    color: #14142b;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  .selected.open {
    border-radius: 6px 6px 0 0;
  }

  .selected:after {
    position: absolute;
    content: '';
    top: 19px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: rgba(25, 22, 50, 0.32) transparent transparent transparent;
  }

  .items {
    color: #14142b;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
    border-right: 1px solid rgba(25, 22, 50, 0.12);
    border-left: 1px solid rgba(25, 22, 50, 0.12);
    border-bottom: 1px solid rgba(25, 22, 50, 0.12);
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .items div {
    color: #14142b;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  .items div:hover {
    background: #f6f6f6;
  }

  .selectHide {
    display: none;
  }
}
</style>
