<template>
  <b-row class="reportsActionsBar mb-3">
    <b-col sm="4" class="mb-2">
      <div class="mb-2">Filters</div>
      <b-form-select v-model="selectedProvider" :options="providers" class="mb-2" />
      <div v-if="!uid" class="mb-2 relative">
        <b-form-input v-model="userIdStr" placeholder="Search by user ID" @keydown="searchByUserId" />
        <BIconX v-if="userIdStr" @click="clearUserIdStr" class="reportFilterInputButton" />
      </div>
      <div class="mb-2 relative">
        <b-form-input v-model="activationIdStr" placeholder="Search by activation ID" @keydown="searchByActivationId" />
        <BIconX v-if="activationIdStr" @click="clearActivationIdStr" class="reportFilterInputButton" />
      </div>
    </b-col>
    <b-col sm="4" class="mb-2">
      <label class="mb-2">
        <span>Status</span>
        <span v-if="selectedReviewStatuses.length > 0" @click="deselectAllReviewStatuses" class="selectAllStatusesBtn">
          Clear status selection
        </span>
      </label>
      <div class="reviewStatusFilters">
        <b-form-checkbox-group
          v-model="selectedReviewStatuses"
          @change="handleSelectReviewStatus"
          :options="reviewStatuses"
        />
      </div>
    </b-col>
    <b-col sm="4" class="mb-2 text-start text-sm-end">
      <div class="mb-2">Actions</div>
      <div class="mb-2">
        <b-button
          @click="showAssignReviewStatusModal = true"
          variant="outline-primary"
          :disabled="checkedReports.size === 0"
        >
          Assign Status
        </b-button>
      </div>
      <div class="mb-2">
        <b-button @click="createCsv" variant="outline-secondary" :disabled="checkedReports.size === 0">
          Create CSV
        </b-button>
      </div>
      <div class="mb-2">
        <b-button @click="createJson" variant="outline-secondary" :disabled="checkedReports.size === 0">
          Create JSON
        </b-button>
      </div>
    </b-col>
  </b-row>
  <AssignReviewStatusModal
    :show-assign-review-status-modal="showAssignReviewStatusModal"
    :checked-reports="checkedReports"
    @hide-assign-review-status-modal="showAssignReviewStatusModal = false"
    @fetch-reports-after-status-update="$emit('fetchReportsAfterStatusUpdate')"
  />
</template>

<script>
import { BIconX } from 'bootstrap-icons-vue'
import { BRow } from 'bootstrap-vue-3'
import AssignReviewStatusModal from '@/components/Reports/AssignReviewStatusModal.vue'
import { useReport } from '@/use/report'
import { useCopy } from '@/use/copy'
import { reviewStatuses } from '@/constants/reportsReviewStatuses'

export default {
  components: {
    AssignReviewStatusModal,
    BRow,
    BIconX
  },
  emits: [
    'handleChangeProvider',
    'searchByUserId',
    'searchByActivationId',
    'filterByReviewStatus',
    'fetchReportsAfterStatusUpdate'
  ],
  props: {
    providers: {
      default: []
    },
    uid: {
      default: ''
    },
    activationId: {
      default: ''
    },
    checkedReports: {
      default: new Map()
    }
  },
  data: () => {
    return {
      selectedProvider: 0,
      userIdStr: '',
      activationIdStr: '',
      selectedReviewStatuses: [],
      showAssignReviewStatusModal: false,
      reviewStatuses
    }
  },
  watch: {
    selectedProvider(newProviderId) {
      this.$emit('handleChangeProvider', newProviderId)
    },
    activationId(newActivationId) {
      this.activationIdStr = newActivationId
    }
  },
  setup() {
    const { getCsvData, getJsonData } = useReport()
    const { copyToCb } = useCopy()
    return {
      getCsvData,
      getJsonData,
      copyToCb
    }
  },
  methods: {
    searchByUserId(event) {
      if (event.key === 'Enter') {
        this.$emit('searchByUserId', this.userIdStr)
      }
    },
    clearUserIdStr() {
      this.userIdStr = ''
      this.$emit('searchByUserId', this.userIdStr)
    },
    searchByActivationId(event) {
      if (event.key === 'Enter') {
        this.$emit('searchByActivationId', this.activationIdStr)
      }
    },
    clearActivationIdStr() {
      this.activationIdStr = ''
      this.$emit('searchByActivationId', this.activationIdStr)
    },
    handleSelectReviewStatus() {
      this.$emit('filterByReviewStatus', this.selectedReviewStatuses)
    },
    deselectAllReviewStatuses() {
      this.selectedReviewStatuses = []
      this.$emit('filterByReviewStatus', this.selectedReviewStatuses)
    },
    createCsv() {
      const checkedReportsArr = Array.from(this.checkedReports.values())
      const csvString = this.getCsvData(checkedReportsArr)
      const blob = new Blob([csvString], { type: 'text/csv' })
      this.downloadFile(blob, 'csv')
    },
    createJson() {
      const checkedReportsArr = Array.from(this.checkedReports.values())
      const checkedReportsData = checkedReportsArr.length > 1 ? checkedReportsArr : checkedReportsArr[0]
      const jsonString = this.getJsonData(checkedReportsData)
      const blob = new Blob([jsonString], { type: 'application/json' })
      this.downloadFile(blob, 'json')
    },
    downloadFile(blob, fileExt) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `reports-${Date.now()}.${fileExt}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style lang="scss">
.reportsActionsBar {
  border-bottom: 1px solid #ccc;

  .reportFilterInputButton {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 22px;
    height: 22px;
  }
  .reviewStatusFilters {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
    & > div > div {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}
</style>
