<template>
  <tbody>
    <tr v-for="(row, index) in reports" :key="index">
      <td class="align-middle cursorPointer" @click="$emit('changeCheckboxState', row.activation.activation_id)">
        <b-form-checkbox :checked="checkedReports.has(row.activation.activation_id)" class="cursorPointer" />
      </td>
      <DateTd :date="row.created_at" />
      <UserIdTd v-if="showUserData" :fb-uid="row.user.fbuid" />
      <UserLevelTd v-if="showUserData" :user="row.user" />
      <ReportActivationRatioTd v-if="showUserData" :user="row.user" />
      <ReportApproveRatioTd v-if="showUserData" :user="row.user" />
      <td class="align-middle noBreak">
        {{ row.activation.provider_id + ' - ' + providers.get(row.activation.provider_id) }}
      </td>
      <td class="align-middle">{{ row.activation.service_name }}</td>
      <td class="align-middle">{{ row.activation.country_name }}</td>
      <td class="align-middle">${{ Number(row.activation.price) / 100 }}</td>
      <PotentialLossTd :price="row.activation.provider_price" />
      <ReviewStatusTd :status="row.activation.report_review_status" />
      <td class="align-middle">
        <b-button @click="$emit('viewReport', row)" variant="outline-secondary" size="sm">View Report</b-button>
      </td>
      <CopyCsvTd :report="row" />
      <CopyJsonTd :report="row" />
    </tr>
  </tbody>
</template>

<script>
import UserLevelTd from '@/components/Reports/Table/UserLevelTd.vue'
import ReportActivationRatioTd from '@/components/Reports/Table/ReportActivationRatioTd.vue'
import ReportApproveRatioTd from '@/components/Reports/Table/ReportApproveRatioTd.vue'
import ReviewStatusTd from '@/components/Reports/Table/ReviewStatusTd.vue'
import PotentialLossTd from '@/components/Reports/Table/PotentialLossTd.vue'
import UserIdTd from '@/components/Reports/Table/UserIdTd.vue'
import DateTd from '@/components/Reports/Table/DateTd.vue'
import CopyCsvTd from '@/components/Reports/Table/CopyCsvTd.vue'
import CopyJsonTd from '@/components/Reports/Table/CopyJsonTd.vue'

export default {
  components: {
    UserIdTd,
    PotentialLossTd,
    ReportApproveRatioTd,
    ReportActivationRatioTd,
    ReviewStatusTd,
    UserLevelTd,
    DateTd,
    CopyCsvTd,
    CopyJsonTd
  },
  emits: ['changeCheckboxState', 'viewReport'],
  props: {
    reports: {
      default: []
    },
    providers: {
      type: Map,
      default: new Map()
    },
    checkedReports: {
      default: new Map()
    },
    showUserData: {
      type: Boolean,
      default: false
    }
  }
}
</script>
