<template>
  <div>
    <b-row class="reportsWrap">
      <b-col v-if="showHeader" cols="12" class="my-3">
        <div class="header">Reports</div>
      </b-col>
      <b-col class="my-2">
        <div>
          <ActionsBar
            :providers="providers"
            :uid="uid"
            :checked-reports="checkedReports"
            :activation-id="activationIdStr"
            @handle-change-provider="handleChangeProvider"
            @search-by-user-id="searchByUserId"
            @search-by-activation-id="searchByActivationId"
            @filter-by-review-status="filterByReviewStatus"
            @fetch-reports-after-status-update="fetchReportsAfterStatusUpdate"
          />
          <transition name="fade" mode="out-in">
            <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
              <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
            </div>
            <div v-else class="table-responsive mb-5">
              <table class="table">
                <THead
                  :show-user-data="showUserData"
                  :checked-reports="checkedReports"
                  @clear-checked-reports="clearCheckedReports"
                />
                <TBody
                  :reports="reportsArr"
                  :providers="providersMap"
                  :checked-reports="checkedReports"
                  :show-user-data="showUserData"
                  @change-checkbox-state="changeCheckboxState"
                  @view-report="openViewReportModal"
                  @close-report="closeViewReportModal"
                />
              </table>
              <Paginator
                @next="showNextPage"
                @prev="showPrevPage"
                @selectPage="selectPage"
                :pagesCount="this.pagesCount"
                :currentPage="this.currentPage"
                :limit="this.limit"
                :itemsCount="this.itemsCount"
                class="pagination"
              />
            </div>
          </transition>
        </div>
      </b-col>
    </b-row>
  </div>
  <ViewReportModal
    :show-view-report-modal="showViewReportModal"
    :viewed-report="viewedReport"
    :checked-reports="checkedReports"
    @close-view-report-modal="closeViewReportModal"
    @change-checkbox-state="changeCheckboxState"
    @fetch-reports-after-status-update="fetchReportsAfterStatusUpdate"
  />
</template>

<script>
import { useReport } from '@/use/report'
import { useMapping } from '@/use/mapping'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import Paginator from '@/components/Paginator.vue'
import THead from '@/components/Reports/Table/THead.vue'
import TBody from '@/components/Reports/Table/TBody.vue'
import ActionsBar from '@/components/Reports/ActionsBar.vue'
import ViewReportModal from '@/components/Reports/ViewReportModal/Index.vue'

export default {
  components: {
    ViewReportModal,
    TBody,
    THead,
    ActionsBar,
    Paginator
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    uid: {
      type: String,
      default: ''
    },
    showUserData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedReports: new Map(),
      providers: [],
      providersMap: new Map(),
      selectedProvider: 0,
      userIdStr: '',
      activationIdStr: '',
      selectedReviewStatuses: [],
      reportsArr: [],
      currentPage: 1,
      limit: 10,
      pagesCount: 1,
      itemsCount: null,
      showViewReportModal: false,
      viewedReport: null
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    }
  },
  setup() {
    const { initApiInst } = useActAdminApi()
    const { getReports, getReviewStatusColorClass } = useReport()
    const { getProviders } = useMapping()
    const { handleCommonErrors } = useErrorHandler()
    return {
      getReports,
      getReviewStatusColorClass,
      initApiInst,
      getProviders,
      handleCommonErrors
    }
  },
  methods: {
    async getReportsData(page = 1) {
      this.$store.commit('loaders/setCommonLoading', true)
      await this.getProvidersData()
      this.reportsArr = []
      const searchParams = { page, limit: this.limit, status: 'inReview' }
      if (this.uid) searchParams.userId = this.uid
      if (this.userIdStr) searchParams.userId = this.userIdStr
      if (this.activationIdStr) searchParams.activationId = this.activationIdStr
      if (this.showUserData) searchParams.withUserData = true
      if (this.selectedProvider) searchParams.providerId = this.selectedProvider
      if (this.selectedReviewStatuses) searchParams.reviewStatus = this.selectedReviewStatuses
      const reportsResp = await this.getReports(searchParams)
      if (reportsResp !== null && reportsResp.reports.length > 0) {
        this.reportsArr = reportsResp.reports
        this.currentPage = reportsResp.page
        this.limit = reportsResp.limit
        this.pagesCount = reportsResp.pages
        this.itemsCount = reportsResp.total
      }
      this.$store.commit('loaders/setCommonLoading', false)
    },
    async getProvidersData() {
      if (this.providersMap.size > 0) return
      this.providers = await this.getProviders()
      this.providers.map((provider) => this.providersMap.set(provider.value, provider.text))
    },
    showNextPage() {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage() {
      this.openPage(this.currentPage - 1)
    },
    selectPage(id) {
      this.openPage(id)
    },
    async openPage(page) {
      this.$store.commit('loaders/setCommonLoading', true)
      await this.getReportsData(page)
      this.$store.commit('loaders/setCommonLoading', false)
    },
    changeCheckboxState(reportId) {
      if (this.checkedReports.has(reportId)) {
        this.checkedReports.delete(reportId)
      } else {
        const report = this.reportsArr.find((r) => r.activation.activation_id === reportId)
        this.checkedReports.set(reportId, report)
      }
    },
    clearCheckedReports() {
      this.checkedReports = new Map()
    },
    async handleChangeProvider(providerId) {
      this.selectedProvider = providerId
      await this.getReportsData()
    },
    async searchByUserId(str) {
      this.userIdStr = str
      await this.getReportsData()
    },
    async searchByActivationId(str) {
      this.activationIdStr = str
      await this.getReportsData()
    },
    async filterByReviewStatus(statuses) {
      this.selectedReviewStatuses = statuses
      await this.getReportsData()
    },
    async fetchReportsAfterStatusUpdate() {
      this.clearCheckedReports()
      await this.getReportsData(this.currentPage)
    },
    openViewReportModal(report) {
      this.showViewReportModal = true
      this.viewedReport = report
    },
    closeViewReportModal() {
      this.showViewReportModal = false
      this.viewedReport = null
    }
  },
  mounted() {
    const activationId = this.$route.query.activation_id
    if (activationId) {
      this.activationIdStr = activationId
      const newQuery = { ...this.$route.query }
      delete newQuery.activation_id
      this.$router.push({ name: this.$route.name, query: newQuery })
    }
    this.getReportsData()
  }
}
</script>

<style lang="scss">
@import '../../assets/animations/fade-data.scss';

.reportsWrap {
  margin-top: 30px;

  .header {
    font-size: 24px;
    border-bottom: 1px solid #717171;
  }
}
</style>
