<template>
  <td class="align-middle text-center" :class="getReviewStatusColorClass(status)">
    {{ splitCamelCase(status) }}
  </td>
</template>

<script>
import { useReport } from '@/use/report'
import { useHelper } from '@/use/helper'

export default {
  props: {
    status: {
      default: ''
    }
  },
  setup() {
    const { getReviewStatusColorClass } = useReport()
    const { splitCamelCase } = useHelper()
    return {
      getReviewStatusColorClass,
      splitCamelCase
    }
  }
}
</script>
