<template>
  <div @click="$emit('sortByField')" :class="{ 'notActive': !sortAsc && !sortDesc }" class="ml-2 sortBtn">
    <BIconSortUp v-if="sortDesc" />
    <BIconSortDownAlt v-else />
  </div>
</template>

<script>
import { BIconSortUp, BIconSortDownAlt } from 'bootstrap-icons-vue'

export default {
  components: { BIconSortUp, BIconSortDownAlt },
  emits: ['sortByField'],
  props: {
    sortAsc: {
      type: Boolean,
      default: () => false
    },
    sortDesc: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
  .sortBtn{
    cursor: pointer;
  }
  .notActive{
    color: #a8a8a8;
  }
</style>
