export default {
  namespaced: true,
  state: {
    copiedRangeTimeoutId: null
  },
  mutations: {
    setCopiedRangeTimeoutId (state, id = null) {
      clearTimeout(state.copiedRangeTimeoutId)
      state.copiedRangeTimeoutId = id
    }
  }
}
