<template>
  <b-row>
    <b-col>
      <b-form @submit="search" @reset="reset">
        <b-row>
          <b-col lg="2">
            <b-form-input v-model="userId" class="my-2" placeholder="UserId" />
          </b-col>
          <b-col lg="2">
            <b-form-input v-model="orderId" placeholder="Order #" class="my-2" />
          </b-col>
          <b-col lg="3">
            <b-form-input v-model="email" placeholder="Email" class="my-2" />
          </b-col>
          <b-col lg="2">
            <b-form-input v-model="activationData" placeholder="Activation id or number" class="my-2" />
          </b-col>

          <b-col lg="3">
            <b-button type="submit" :disabled="searchLoading" class="px-5 my-2 searchBtn" variant="primary">
              <b-spinner v-if="searchLoading" small variant="light" />
              <span v-else>Search</span>
            </b-button>
            <b-button type="reset" class="my-2 mx-2" variant="outline-warning"> Reset </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
export default {
  emits: ['searchUser', 'searchReset'],
  data() {
    return {
      userId: null,
      orderId: null,
      email: null,
      activationData: null
    }
  },
  computed: {
    searchLoading() {
      return this.$store.state.loaders.searchLoading
    }
  },
  methods: {
    async search() {
      this.$emit('searchUser', this.userId, this.email, this.orderId, this.activationData)
      this.userId = this.email = this.orderId = this.activationData = null
    },
    reset() {
      this.$store.commit('loaders/setSearchLoading', false)
      this.userId = this.email = this.orderId = this.activationData = null
      this.$emit('searchReset')
    }
  },
  mounted() {
    const userId = this.$route.query.user_id
    if (userId) {
      this.userId = userId
      const newQuery = { ...this.$route.query }
      delete newQuery.user_id
      this.$router.push({ name: this.$route.name, query: newQuery })
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchBtn {
  width: 150px;
}
</style>
