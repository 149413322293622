<template>
  <div v-if="pagesCount > 1" class="paginator">
    <ul class="paginator__list">

      <li
        @click="handleClickPrev"
        class="paginator__switcher"
        :class="currentPage > 1 ? 'paginator__switcher--active' : 'paginator__switcher--disabled'"
      >
        <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.16016 1.41L3.58016 6L8.16016 10.59L6.75016 12L0.750156 6L6.75016 0L8.16016 1.41Z" />
        </svg>
      </li>

      <template v-if="pagesCount < maxCountPage">
        <li
          v-for="page in pagesCount"
          @click="handleClickPage(page)"
          :key="page"
          class="paginator__item"
          :class="{'paginator__item--active': page === currentPage}"
        >
          {{ page }}
        </li>
      </template>

      <template v-else-if="currentPage < viewCountPage">
        <li
          v-for="page in viewCountPage"
          @click="handleClickPage(page)"
          :key="page"
          class="paginator__item"
          :class="{'paginator__item--active': page === currentPage}"
        >
          {{ page }}
        </li>

        <li class="paginator__item">...</li>

        <li
          @click="handleClickPage(pagesCount)"
          class="paginator__item"
          :class="{'paginator__item--active': pagesCount === currentPage}"
        >
          {{ pagesCount }}
        </li>
      </template>

      <template v-else-if="pagesCount - currentPage < viewCountPage - 1">
        <li
          @click="handleClickPage(1)"
          class="paginator__item"
          :class="{'paginator__item--active': currentPage === 1}"
        >
          {{ 1 }}
        </li>

        <li class="paginator__item">...</li>

        <li
          v-for="page in viewCountPage"
          :key="page"
          @click="handleClickPage(pagesCount + (page - viewCountPage))"
          class="paginator__item"
          :class="{'paginator__item--active': pagesCount + (page - viewCountPage) === currentPage}"
        >
          {{ pagesCount + (page - viewCountPage) }}
        </li>
      </template>

      <template v-else-if="pagesCount >= viewCountPage">
        <li
          @click="handleClickPage(1)"
          class="paginator__item"
          :class="{'paginator__item--active': currentPage === 1}"
        >
          {{ 1 }}
        </li>

        <li class="paginator__item">...</li>

        <li
          v-for="page in minViewPage"
          :key="page"
          @click="handleClickPage(page - minViewPageHelper + currentPage)"
          class="paginator__item"
          :class="{'paginator__item--active': page - minViewPageHelper + currentPage=== currentPage}"
        >
          {{ page - minViewPageHelper + currentPage }}
        </li>

        <li class="paginator__item">...</li>

        <li
          @click="handleClickPage(pagesCount)"
          class="paginator__item"
          :class="{'paginator__item--active': pagesCount === currentPage}"
        >
          {{ pagesCount }}
        </li>
      </template>

      <li
        @click="handleClickNext"
        class="paginator__switcher"
        :class="currentPage < pagesCount ? 'paginator__switcher--active' : 'paginator__switcher--disabled'"
      >
        <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.839844 1.41L5.41984 6L0.839844 10.59L2.24984 12L8.24984 6L2.24984 0L0.839844 1.41Z" />
        </svg>
      </li>

    </ul>
    <p class="paginator__counter-mark">{{ showingText }}</p>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: Number,
    pagesCount: Number,
    limit: Number,
    itemsCount: Number
  },
  emits: ['prev', 'next', 'selectPage'],
  data () {
    return {
      viewCountPage: 5,
      maxCountPage: 8,
      minViewPage: 3,
      minViewPageHelper: 2
    }
  },
  computed: {
    showingText () {
      return 'limit of total'.replace('limit', this.limit).replace('total', this.itemsCount)
    }
  },
  methods: {
    handleClickPrev () {
      if (this.currentPage > 1) this.$emit('prev')
    },
    handleClickNext () {
      if (this.currentPage < this.pagesCount) this.$emit('next')
    },
    handleClickPage (id) {
      this.$emit('selectPage', id)
    },
    handleView () {
      if (window.innerWidth <= 590) {
        this.viewCountPage = 3
        this.maxCountPage = 6
        this.minViewPage = 1
        this.minViewPageHelper = 1
      }
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  unmounted () {
    window.removeEventListener('resize', this.handleView)
  }
}
</script>

<style lang="scss" scoped>
  .paginator {
    color: #14142b;
    display: flex;
    justify-content: space-between;

    &__list {
      display: flex;
    }

    &__item {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfe3e8;
      border-radius: 4px;
      margin: 0 4px;
      font-size: 14px;
      cursor: pointer;

      &--active {
        border: 1px solid #fa8231;
      }
    }

    &__switcher {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin: 0 4px;
      cursor: pointer;

      &--active {
        background: #fa8231;
        border-radius: 4px;
        path {
          fill: #fff;
        }
      }

      &--disabled {
        background: #E4E4E4;
        opacity: 0.5;
        path {
            fill: rgba(40, 45, 50, 0.2);
        }
      }
    }

    &__counter-mark {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #11142D;
      opacity: 0.8;
      display: flex;
      align-items: center;
    }
  }

   @media (max-width: 590px) {
     .paginator {
       flex-direction: column;

       &__counter-mark {
         text-align: center;
         padding-top: 10px;
         font-size: 14px;
         line-height: 20px;
         justify-content: center;
        }
     }
   }
</style>
