<template>
  <b-modal v-model="show" centered :title="mode === 'edit' ? 'Edit service' : 'Add new service'" hide-footer>
    <div class="d-block mx-2">
      <b-form @submit="saveService">
        <div class="mb-3">
          <label>Service Id:</label>
          <b-form-input v-model="service.id" :disabled="mode === 'edit'" type="text" required />
        </div>
        <div class="mb-3">
          <label>Full name:</label>
          <b-form-input v-model="service.full_name" @focusout="fillIdAltNameFields()" type="text" required />
        </div>
        <div class="mb-3">
          <label>Alt names:</label>
          <b-form-textarea v-model="service.alt_name" rows="2" required />
        </div>
        <div class="mb-3">
          <label>Icon filename:</label>
          <b-form-input v-model="service.icon_filename" type="text" />
        </div>
        <div class="mb-3">
          <label>Snippet enabled:</label>
          <b-form-select v-model="service.snippet_enabled" :options="selectOptions" />
        </div>
        <div class="mb-3">
          <label>Warning enabled:</label>
          <b-form-select v-model="service.warning_enabled" :options="selectOptions" />
        </div>

        <div class="d-flex justify-content-center mt-3 mb-3">
          <b-button type="submit" variant="primary">Save service</b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import { useService } from '@/use/service'
import { useActAdminApi } from '@/use/actAdminApi'

export default {
  emits: ['hideModal', 'serviceSaved'],
  props: {
    showModal: Boolean,
    serviceData: Object,
    mode: {
      type: String,
      default: () => 'add'
    }
  },
  data: () => {
    return {
      show: false,
      service: {},
      selectOptions: [
        { value: 0, text: 'No' },
        { value: 1, text: 'Yes' }
      ]
    }
  },
  watch: {
    showModal() {
      this.show = this.$props.showModal
    },
    show(val) {
      if (!val) {
        this.$emit('hideModal')
        this.service = {}
      } else {
        this.service = this.serviceData
        if (!this.service.icon_filename) this.service.icon_filename = 'services/no_icon.png'
        if (!this.service.snippet_enabled) this.service.snippet_enabled = 0
        if (!this.service.warning_enabled) this.service.warning_enabled = 0
      }
    }
  },
  setup() {
    const { initApiInst, actMappingApi } = useActAdminApi()
    const { createServiceIdFromName } = useService()
    return { initApiInst, actMappingApi, createServiceIdFromName }
  },
  methods: {
    async saveService(event) {
      event.preventDefault()
      if (this.mode === 'add') {
        await this.createService()
      } else if (this.mode === 'edit') {
        await this.updateService()
      }
    },
    async createService() {
      const service = this.service
      try {
        await this.initApiInst()
        const resp = await this.actMappingApi.createService({ service })
        if (resp.result && resp.result === 'success') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Service successfully created',
            timeout: 3000,
            type: 'success',
            name: 'success_service_created'
          })
          this.$emit('serviceSaved', this.service)
        }
      } catch (e) {
        let errMessage = 'Service creation error! Try again later'
        if (e.response && e.response.status === 409) {
          errMessage = 'Service with such Id already exists'
        }
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: errMessage,
          timeout: 3000,
          type: 'error',
          name: 'error_service_create'
        })
      }
    },
    async updateService() {
      const service = this.service
      const serviceId = service.id
      if (!serviceId) return
      const updatedServiceData = Object.assign({}, service)
      delete updatedServiceData.id
      try {
        await this.initApiInst()
        const resp = await this.actMappingApi.updateService(serviceId, { service1: updatedServiceData })
        if (resp.result && resp.result === 'success') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Service successfully updated',
            timeout: 3000,
            type: 'success',
            name: 'success_service_updated'
          })
          this.$emit('serviceSaved', this.service)
        }
      } catch (e) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Update service error. Try again later',
          timeout: 3000,
          type: 'error',
          name: 'error_service_update'
        })
      }
    },
    fillIdAltNameFields() {
      if (this.service.full_name) {
        if (!this.service.id) {
          this.service.id = this.createServiceIdFromName(this.service.full_name)
        }
        if (!this.service.alt_name) this.service.alt_name = this.service.full_name
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  color: #686868;
}
</style>
